@import './antdThemeConfig.less';
@import './fontsConnection.less';

body {
  margin: 0;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Poppins, sans-serif;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  box-shadow: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//BUTTONS
.ant-btn {
  font-family: Poppins, sans-serif;
}

// TABLE
.ant-table-wrapper {
  .ant-table {
    & table {
      border: none;
    }
    & .ant-table-thead > tr > th {
      font-size: 11px;
      font-weight: 500;
      background-color: @light-bg;
      border-bottom-width: 2px;
      font-family: Poppins, sans-serif;
      color: rgba(0, 0, 0, 0.5);
      padding: '12px';
      border: none;
    }
    & .ant-table-tbody > tr > td {
      border-bottom-width: 2px;
    }
    & .ant-table-row,
    & .ant-table-thead > tr {
      height: 40px;
    }
    & .ant-table-placeholder {
      display: none;
    }
    & .ant-table-column-sorters {
      display: none;
    }
    & .ant-table-column-sorter {
      display: none;
    }
  }
  .ant-table-pagination {
    margin-bottom: 0;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
}
