@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins-Medium.ttf');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Manrope';
    src: url('assets/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
  }
  
  